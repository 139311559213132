.glow {
  color: #fff;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #4e73df,
      0 0 40px #4e73df, 0 0 50px #4e73df, 0 0 60px #4e73df, 0 0 70px #4e73df;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff,
      0 0 60px #fff, 0 0 70px #fff, 0 0 80px #fff;
  }
}

.glowGreen {
  cursor: pointer;
  color: #fff;
  -webkit-animation: glowGreen 1s ease-in-out infinite alternate;
  -moz-animation: glowGreen 1s ease-in-out infinite alternate;
  animation: glowGreen 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glowGreen {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #1cc88a,
      0 0 40px #1cc88a, 0 0 50px #1cc88a, 0 0 60px #1cc88a, 0 0 70px #1cc88a;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff,
      0 0 60px #fff, 0 0 70px #fff, 0 0 80px #fff;
  }
}

/* todo style */
@import url('https://fonts.googleapis.com/css?family=Architects+Daughter&display=swap');

.todolist {
  margin: 50px auto;
  padding: 16px;
  min-height: 566px;
  /* max-width: 300px; */
  width: 66%;
  background-color: #f1f5f8;
  background-image: radial-gradient(#bfc0c1 7.2%, transparent 0);
  background-size: 40px 40px;
  border-radius: 20px;
  box-shadow: 4px 3px 7px 2px #00000040;
  font-family: 'Architects Daughter', sans-serif;
  text-align: center;
  margin-top: 0px;
}

._heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._heading h1 {
  width: 70%;
  margin-top: 30px;
  border-radius: 10px;
  border-radius: 20% 5% 20% 5%/5% 20% 25% 20%;
  padding: 0.2rem 1.2rem;
  color: #494a4b;
  background-color: #fdc66e;
}

.todolist form {
  margin-top: 40px;
}

.todolist input {
  border: 0;
  border-bottom: 3px dashed #fdcb6e;
  background-color: transparent;
  font-family: 'Architects Daughter', sans-serif;
  font-size: 1.3rem;
  padding: 10px 15px;
  width: 70%;
  color: #494a4b;
}

.todolist button {
  margin-top: 30px;
  border: 3px solid hsl(198, 1%, 29%);
  border-radius: 8px;
  padding: 10px 15px;
  font-family: 'Architects Daughter', sans-serif;
  font-size: 0.9rem;
}

.items {
  margin: 50px 0 0 30px;
  width: 100%;
  margin-left: 0px;
}

.items ul {
  margin-left: -3%;
}

.items ul li {
  text-align: left;
  margin: 20px 10px;
  font-size: 1.4rem;
  font-weight: 300;
  color: #494a4b;
  word-break: break-all;
}

.border-success {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.removeQuery {
  margin-left: 89% !important;
  width: 15% !important;
  margin-top: -4% !important;
}


.Select-menu-outer {
  max-height: 200px; /* Set your desired max height */
  overflow-y: auto; /* Add vertical scroll if content exceeds max height */
}